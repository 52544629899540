import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import axios from 'axios';
import Swal from 'sweetalert'
import ReactApexChart from 'react-apexcharts';
import {REACT_APP_URL} from '../../env';
import Dashboard from "../../pages/Dashboard";

const Compare = () => {

    const [district, setDistrict] = useState([]);

    const [selectDistricts, setSelectDistricts] = useState([]);

    const [assessmentOption, setAssessmentOption] = useState("");

    const [assessmentType, setAssessmentType] = useState("");

    const [compareData, setCompareData] = useState([]);

    const fetchDistrict = () => {

        axios.get(`${REACT_APP_URL}/get-all-districts/`)
        
        .then((response) =>{
    
            setDistrict(response.data);
    
        })
        .catch((error) => {
    
            console.log("Error: " + error);
        });
    }

    const assessmentOptions = [
        { value: 'Literacy', label: 'Literacy' },
        { value: 'Numeracy', label: 'Numeracy' },
        { value: 'Math Operations', label: 'Math Operations'},
    ]

    const assessmentTypeOptions = [
        {value: "Baseline Assessment", label: "Baseline Assessment"},
        {value: "Midterm Assessment", label: "Midterm Assessment"},
    ]


    const handleAssessmentTypeChange = (selectedOption) => {

        setAssessmentOption(selectedOption);
    }

    const handleAssessmentChange = (selectedOption) => {

        setAssessmentType(selectedOption);

    }

    const handleOnChange = (selectedOptions) => {

        setSelectDistricts(selectedOptions)

    };

    useEffect(() => {

        fetchDistrict();

    }, []);

    const getComparison = () => {

        const districts_names = [];

        if(!selectDistricts || selectDistricts === undefined || selectDistricts.length == 0){

            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Select Districts!',
                footer: 'HCD Incubator'
            })
        }
        else if(selectDistricts.length == 1){

            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Select At Least Two Districts!',
                footer: 'HCD Incubator'
            })
        }
        else if(selectDistricts == ""){

            alert("error");
        }
        else if(assessmentOption == ""){

            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Select An Assessment Type!',
                footer: 'HCD Incubator'
            })
        }
        else if(assessmentType == ""){

            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Select An Assessment!',
                footer: 'HCD Incubator'
            })
        }
        else{

            for(let i = 0; i < selectDistricts.length; i++){
    
                districts_names.push(selectDistricts[i].value)
            }
    
            axios.get(`${REACT_APP_URL}/compare-assessments-by-districts/`,{
                params:{
                    districts:districts_names,
                    assessment:assessmentType.value,
                    assessment_type: assessmentOption.value
                }
            })
            .then((response) => {

                    setCompareData(response.data);
            })
            .catch((error) => {

                console.log(error)
            });
        }

        
    }

    const options = {

        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5', 'Class 6'],
        },
        yaxis: {
            title: {
            text: 'Percentage'
            },
            min: 0,
            max: 100,
        },
        fill: {
            opacity: 1
        },

    };

    return(

        <Dashboard>
            <div className="container-fluid" style={{marginTop: "2%"}}>
                <div className="card container-fluid mobile-m">
                    <div className="card-content">
                        <div className="grid">
                            <div className="row">
                                <div className="cell-md-12">
                                    <h6 className="pt-4">Select District(s)</h6>
                                    <Select options={district} isMulti onChange={handleOnChange} value={selectDistricts}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-6">
                                    <h6 className="pt-4">Select Assessment Type</h6>
                                    <Select options={assessmentTypeOptions} onChange={handleAssessmentTypeChange} value={assessmentOption}/>
                                </div>
                                <div className="cell-md-6">
                                    <h6 className="pt-4">Select Assessment</h6>
                                    <Select options={assessmentOptions} onChange={handleAssessmentChange} value={assessmentType}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <p><button className="button" style={{backgroundColor: "#17162c", color:"#fff"}} onClick={getComparison}>Compare</button></p>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card container-fluid">
                    <div className="card-content">
                        <div className="grid">
                            <div className="row">
                                {
                                    compareData.map((item, index) => {
                                            return(
                                                item.map((data, i) => {
                                                    return(

                                                        <div className="cell-md-6" key={i}>
                                                            <h4>{data.district_name}</h4>
                                                            <ReactApexChart options={options} series={data.data} type="bar" height={350} />
                                                        </div>
                                                    )
                                                })
                                            )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    );
}

export default Compare;