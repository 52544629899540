import React from 'react';
import save_the_children from '../images/save_the_children.jpg';
import rising_academy_network from '../images/rising_academy_network.jpg';
import world_vision from '../images/world_vision.png';
import educaid from '../images/educaid.jpg';
import Metro from 'metro4-dist/js/metro';
import {Link} from 'react-router-dom';

const ServiceProviders = (props) => {

    return(
        <div className="container-fluid">
            <div className="grid">
                <div className="row">
                    <div className="cell-md-4">
                        <div className="card p-4">
                            <div className="card-header">
                                <p className="text-leader text-center">Save the Children</p>
                            </div>
                            <div className="card-content text-center">
                                <p>
                                    <img src={save_the_children} alt="save_the_children.jpg"/>
                                </p>
                                <p className="text-leader">
                                    <strong>Project Name</strong>
                                </p>
                                <p>
                                    Literacy and Numeracy Boost
                                </p>
                                <p className="text-leader">
                                    <strong>District</strong>
                                </p>
                                <p>
                                    Pujehun
                                </p>
                                <p className="text-leader">
                                    <strong>Number of Schools</strong>
                                </p>
                                <p>15</p>
                            </div>
                            <div className="card-footer text-center">
                                <p className="text-center">
                                    <Link to="/save-the-children">
                                        <button className="button fg-white" style={{backgroundColor:"#17172c"}}><span className="mif-chart-bars"></span> View Analysis</button>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cell-md-4">
                        <div className="card p-4">
                            <div className="card-header">
                                <p className="text-leader text-center">Rising Academy Network</p>
                            </div>
                            <div className="card-content text-center">
                                <p>
                                    <img src={rising_academy_network} alt="ran.jpg"/>
                                </p>
                                <p className="text-leader">
                                    <strong>Project Name</strong>
                                </p>
                                <p>
                                    Betteh  Buk  Betteh  Bambai  (B⁴)
                                </p>
                                <p className="text-leader">
                                    <strong>District</strong>
                                </p>
                                <p>
                                    Bo, Kailahun, Kenema, Western Area Urban
                                </p>
                                <p className="text-leader">
                                    <strong>Number of Schools</strong>
                                </p>
                                <p>25</p>
                            </div>
                            <div className="card-footer text-center">
                                <p className="text-center">
                                    <Link to="/rising-academy-network">
                                        <button className="button fg-white" style={{backgroundColor:"#17172c"}}><span className="mif-chart-bars"></span> View Analysis</button>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cell-md-4">
                        <div className="card p-4">
                            <div className="card-header">
                                <p className="text-leader text-center">EducAid</p>
                            </div>
                            <div className="card-content text-center">
                                <p>
                                    <img src={educaid} alt="educaid.jpg"/>
                                </p>
                                <p className="text-leader">
                                    <strong>Project Name</strong>
                                </p>
                                <p>
                                    Quality Enhancement Programme
                                </p>
                                <p className="text-leader">
                                    <strong>District</strong>
                                </p>
                                <p>
                                    Bombali, Kambia, Karene, Koinadugu, Port Loko, Tonkolili
                                </p>
                                <p className="text-leader">
                                    <strong>Number of Schools</strong>
                                </p>
                                <p>60</p>
                            </div>
                            <div className="card-footer text-center">
                                <p className="text-center">
                                    <Link to="/educaid">
                                        <button className="button fg-white" style={{backgroundColor:"#17172c"}}><span className="mif-chart-bars"></span> View Analysis</button>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cell-md-4">
                        <div className="card p-4">
                            <div className="card-header">
                                <p className="text-leader text-center">World Vision International</p>
                            </div>
                            <div className="card-content text-center">
                                <p>
                                    <img src={world_vision} alt="wv.jpg"/>
                                </p>
                                <p className="text-leader">
                                    <strong>Project Name</strong>
                                </p>
                                <p>
                                    Unlocking Literacy through Leveraging Digital Technologies in Sierra Leone
                                </p>
                                <p className="text-leader">
                                    <strong>District</strong>
                                </p>
                                <p>
                                    Bo, Bonthe, Kono, Pujehun
                                </p>
                                <p className="text-leader">
                                    <strong>Number of Schools</strong>
                                </p>
                                <p>40</p>
                            </div>
                            <div className="card-footer text-center">
                                <p className="text-center">
                                    <Link to="/world-vision-international">
                                        <button className="button fg-white" style={{backgroundColor:"#17172c"}}><span className="mif-chart-bars"></span> View Analysis</button>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cell-md-4">
                        <div className="card p-4">
                            <div className="card-header">
                                <p className="text-leader text-center">National Youth Awareness Forum</p>
                            </div>
                            <div className="card-content text-center">
                                <p className="text-leader">
                                    <strong>Project Name</strong>
                                </p>
                                <p>
                                    Improving the Quality of Primary Education in Sierra Leone
                                </p>
                                <p className="text-leader">
                                    <strong>District</strong>
                                </p>
                                <p>
                                    Bonthe, Moyamba, Western Area Rural
                                </p>
                                <p className="text-leader">
                                    <strong>Number of Schools</strong>
                                </p>
                                <p>30</p>
                            </div>
                            <div className="card-footer text-center">
                                <p className="text-center">
                                    <Link to="/nyafsl">
                                        <button className="button fg-white" style={{backgroundColor:"#17172c"}}><span className="mif-chart-bars"></span> View Analysis</button>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ServiceProviders;