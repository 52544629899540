import React, { useState, useEffect} from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import {REACT_APP_URL} from '../../env';


const StudentPerLevel = () => {

    const [data, setData] = useState("");

    const fetchData = () => {
    
        axios.get(`${REACT_APP_URL}/get-students-assessed-per-level/`)
        
        .then((response) =>{
    
            setData(response.data);

            console.log(response.data);
    
        })
        .catch((error) => {
    
            console.log("Error: " + error);
        });
    }

    /*
    const options = {

        chart: {
            height: 350,
            type: 'bar',
            id: 'studentPerLevel',
            toolbar: {
                show: true
            },
            zoom: {
                enabled: false
            },
            events: {
              click: function(chart, w, e) {
                // console.log(chart, w, e)
              }
            }
        },
        colors: ['#F44336', '#0d47a1', '#004d40', '#E91E63', '#9C27B0', '#3e2723'],
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: ["Class 1", "Class 2", "Class 3", "Class 4", "Class 5", "class 6"],
            labels: {
                style: {
                  colors: ['#F44336', '#0d47a1', '#004d40', '#E91E63', '#9C27B0', '#3e2723'],
                  fontSize: '12px'
                }
            }
        }
    }; 
    */

    const options = {
        chart: {
            width: 380,
            type: 'donut',
            dropShadow: {
                enabled: true,
                color: '#111',
                top: -1,
                left: 3,
                blur: 3,
                opacity: 0.2
            }
        },
        stroke: {
            width: 0,
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            showAlways: true,
                            show: true
                        }
                    }
                }
            }
        },
        labels: ["Class 1", "Class 2", "Class 3", "Class 4", "Class 5", "Class 6"],
            dataLabels: {
            dropShadow: {
                blur: 3,
                opacity: 0.8
            }
        },
        fill: {
            type: 'pattern',
                opacity: 1,
                pattern: {
                enabled: true,
                    style: ['verticalLines', 'squares', 'horizontalLines', 'circles','slantedLines'],
            },
        },
        states: {
            hover: {
                filter: 'none'
            }
        },
        theme: {
            palette: 'palette2'
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }

    const series = Array.from(data);

    useEffect(() => {
    
            fetchData();
    }, []);

    return(

        <div id="studentPerLevel">
            <Chart options={options} series={series} type="donut" height={350} />
        </div>
    );
}

export default StudentPerLevel;


