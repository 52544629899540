import React, {useState, useEffect} from 'react';
import Dashboard from "../pages/Dashboard";
import "leaflet/dist/leaflet.css";
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import axios from 'axios';
import {REACT_APP_URL} from "../env";
import LiteracyOverall from './schools/LiteracyOverall';
import NumeracyOverall from './schools/NumeracyOverall';
import MathOperations from "./schools/MathOperations";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;


const SingleSchoolDirectory = (props) => {

    const position = [8.5605551, -11.578891];

    const [data, setData] = useState([]);

    const [geoData, setGeoData] = useState({lat:null,lng:null});

    const fetchData = () => {

        axios.get(`${REACT_APP_URL}/single-school-listing/?emis-code=${props.match.params.emis}`)
            .then((response) => {

                setData(response.data);
                setGeoData({lat: response.data.latitude, lng: response.data.longitude})

            })
            .catch((error) => {

                console.log(error);
            })
    }

    useEffect(() => {

        fetchData();
    },[])

    return(
        <Dashboard>
            <div className="container-fluid">
                <div className="grid">
                    <div className="row">
                        <div className="cell-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <p className="text-center text-leader">{data.school_name}</p>
                                </div>
                                <div className="card-content p-4">
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <table className="table compact row-border striped text-leader2">
                                                <tbody>
                                                <tr>
                                                    <td>School Name: {data.school_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Emis Code: {data.school_emis}</td>
                                                </tr>
                                                <tr>
                                                    <td>District: {data.school_district}</td>
                                                </tr>
                                                <tr>
                                                    <td>Chiefdom: {data.school_chiefdom}</td>
                                                </tr>
                                                <tr>
                                                    <td>Owner: {data.school_owner}</td>
                                                </tr>
                                                <tr>
                                                    <td>Remoteness: {data.school_remoteness}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="cell-md-6">
                                            <MapContainer center={position} zoom={7} scrollWheelZoom={false} style={{width: "100%", height: "40vh"}}>
                                                <TileLayer
                                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                {geoData.lat && geoData.lng &&
                                                    <Marker position={[geoData.lat, geoData.lng]}>
                                                        <Popup>
                                                            <p className="text-leader text-center">{data.school_name}</p>
                                                        </Popup>
                                                    </Marker>
                                                }
                                            </MapContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cell-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="text-center">Analysis</h3>
                                    <div className="row">
                                        <div className="cell-md-6">
                                        <p className="text-center text-leader2">Percentage of Literacy Level Attained (Overall)</p>
                                            <LiteracyOverall emis_code={`${props.match.params.emis}`}/>
                                        </div>
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">
                                                Percentage of Student That Can Identify Digits
                                            </p>
                                            <NumeracyOverall emis_code={`${props.match.params.emis}`}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Students That Can Do Math Operations</p>
                                            <MathOperations emis_code={`${props.match.params.emis}`}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    );
}

export default SingleSchoolDirectory;