import React, {useEffect, useState} from 'react';
import axios from "axios";
import {REACT_APP_URL} from "../env";
import ReactApexChart from "react-apexcharts";


const AggregatedLiteracyBaseline = () => {

    const [data, setData] = useState("");

    const fetchData = () => {

        axios.get(`${REACT_APP_URL}/baseline-tc-literacy/`)

            .then((response) =>{

                setData(response.data);

            })
            .catch((error) => {

                console.log("Error: " + error);
            });
    }

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Cannot Identify Letters', 'Can Identify Letter', 'Can Pronounce Word', 'Can Read Paragraph', 'Can Read and Comprehend Story'],
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "%"
                }
            }
        }
    };

    useEffect(() => {

        fetchData();
    }, []);

    return(
        <div id="agl-chart-numeracy-aggregated">
            <ReactApexChart options={options} series={data} type="bar" height={350} />
        </div>
    )
}

export default AggregatedLiteracyBaseline;