import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {REACT_APP_URL} from "../env";


const DataCount = () => {

    const [data, setData] = useState("");

    const [loading, isLoading] = useState(false);


    const fetchData = () => {

        axios.get(`${REACT_APP_URL}/get-data-count/`)
        
        .then((response) =>{

            setData(response.data);

            isLoading(false);
        })
        .catch((error) => {

            console.log("Error: " + error);
        });
    }

    useEffect(() => {

            fetchData();
    }, []);

    return(

    <div className="row">
        <div className="cell-md-3">
            <div className="icon-box bg-blue fg-white">
                <div className="icon"><span className="mif-map"></span></div>
                <div className="content">
                    <div className="p-2">
                        <p className="text-bold text-leader">DISTRICTS</p>
                        <div className="text-bold text-leader">{data.districts}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="cell-md-3">
            <div className="icon-box bg-green fg-white">
                <div className="icon"><span className="mif-map2"></span></div>
                <div className="content">
                    <div className="p-2">
                        <p className="text-bold text-leader">CHIEFDOMS</p>
                        <div className="text-bold text-leader">{data.chiefdoms}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="cell-md-3">
            <div className="icon-box bg-orange fg-white">
                <div className="icon"><span className="mif-school"></span></div>
                <div className="content">
                    <div className="p-2">
                        <p className="text-bold text-leader">SCHOOLS</p>
                        <div className="text-bold text-leader">{data.schools}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="cell-md-3">
            <div className="icon-box bg-indigo fg-white">
                <div className="icon"><span className="mif-users"></span></div>
                <div className="content">
                    <div className="p-2">
                        <p className="text-bold text-leader">STUDENTS</p>
                        <div className="text-bold text-leader">{data.students}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    );
}

export default DataCount;