import React from 'react';
import Dashboard from "../pages/Dashboard";
import LiteracyOverall from "./control_aggregated/LiteracyOverall";
import NumeracyOverall from "./control_aggregated/NumeracyOverall";
import MathOperations from "./control_aggregated/MathOperations";

const ControlSchoolsAggregated = () => {

    return(

        <Dashboard>
            <div className="container-fluid mt-4">
                <div className="grid">
                    <div className="row">
                        <div className="cell-md-12">
                            <div className="card p-4">
                                <div className="card-content">
                                    <h2 className="text-center">Control Schools</h2>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Literacy Level Attained (Overall)</p>
                                            <LiteracyOverall/>
                                        </div>
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">
                                                Percentage of Student That Can Identify Digits
                                            </p>
                                            <NumeracyOverall/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Students That Can Do Math Operations</p>
                                            <MathOperations/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}

export default ControlSchoolsAggregated;