import React, {useEffect, useState} from  'react';
import axios from "axios";
import {REACT_APP_URL} from "../../../env";
import Chart from 'react-apexcharts';

const NumeracyOverall = (props) => {

    const [data, setData] = useState("");

    const fetchData = () => {

        axios.get(`${REACT_APP_URL}/numeracy-level-sp/`,{
            params:{
                "service_provider": "Save the Children"
            }
        })
        
        .then((response) =>{

            setData(response.data);

        })
        .catch((error) => {

            console.log("Error: " + error);
        });
    }

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Beginner', 'One Digit', 'Two Digits', 'Three Digits'],
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "%"
                }
            }
        }
    };

    useEffect(() => {

        fetchData();
    }, []);


    return(

        <div id="ds-chart-nn-overall">
            <Chart options={options} series={data} type="bar" height={350} />
        </div>

    );
}

export default NumeracyOverall;