import React, { useState } from 'react';
import axios from 'axios';
import { REACT_APP_URL } from '../env';
import { useEffect } from 'react';


const GenderCount = () => {

    const [data, setData] = useState("");


    const fetchData = () => {

        axios.get(`${REACT_APP_URL}/get-gender-total/`)
        .then((response) => {

            setData(response.data);
        })
        .catch((error) => {

            console.log("Error : " + error);
        })
    }

    useEffect(() => {

        fetchData();
    },[]);

    return(

        <div className="row">
            <div className="cell-md-6">
                <div className="icon-box bg-blue fg-white">
                    <div className="icon"><span className="mif-user"></span></div>
                    <div className="content">
                        <div className="p-2">
                            <div className="text-leader text-bold">Total Males</div>
                            <div className="text-bold text-leader">{data.Male}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cell-md-6">
                <div className="icon-box bg-indigo fg-white">
                    <div className="icon"><span className="mif-user"></span></div>
                    <div className="content">
                        <div className="p-2">
                            <div className="text-bold text-leader">Total Females</div>
                            <div className="text-bold text-leader">{data.Female}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GenderCount;