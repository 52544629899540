import React, {useState, useEffect} from 'react';
import axios from 'axios';
import urlSlug from 'url-slug'
import { REACT_APP_URL } from '../env';
import {useHistory} from "react-router-dom";

const SchoolsDirectory = () => {

    const [loading, setLoading] = useState(true);

    const history = useHistory()

    const [search, setSearch] = useState("");

    const [data, setData] = useState([]);

    const getData = () => {

        axios.get(`${REACT_APP_URL}/list-of-schools/`)
        .then((response) => {

            setLoading(false);
            setData(response.data);

        })
        .catch((error) => {

            console.log("Error");
        })
    }

    useEffect(() => {

        getData();


    }, []);

    return (
            <>{/*
                <p>
                    <input type="text" data-role="input" data-prepend="Search" value={search} onChange={(e) => {
                        setSearch(e.target.value)
                    }}/>
                </p>
                */}
                <table className="table compact row-border striped">
                    <thead>
                    <tr>
                        <th>School Name</th>
                        <th>Emis Code</th>
                        <th>District</th>
                        <th>Chiefdom</th>
                        <th>School Owner</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.school_name}</td>
                                    <td>{item.emis_code}</td>
                                    <td>{item.district}</td>
                                    <td>{item.chiefdom}</td>
                                    <td>{item.school_owner}</td>
                                    <td>
                                        <a onClick={() => {
                                            history.push(`/school/${urlSlug(item.school_name)}/${item.emis_code}`);
                                        }
                                        } className="button primary outline">View Details <span
                                            className="mif-arrow-right"></span></a>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                    <tbody>

                    </tbody>
                </table>
            </>
    );
}

export default SchoolsDirectory;