import React, {useEffect, useState} from "react";
import Select from 'react-select';
import axios from 'axios';
import {REACT_APP_URL} from "../env";
import Swal from "sweetalert";
import ReactApexChart from "react-apexcharts";

const AdvancedAnalysis = () => {
    const [selectedType, setSelectedType] = useState("");
    const [selectedSchoolType, setSelectedSchoolType] = useState("");
    const [selectedGenderType, setSelectedGenderType] = useState(""); 
    const [selectedProviderType, setSelectedProviderType] = useState("");
    const [selectedAssessmentType, setSelectedAssessmentType] = useState("");

    const [maxValueForChartYaxis, setMaxValue] = useState(0);
    const [chartLegend, setChartLengend] = useState("");

    // Show / Hide States
    const [displaySchoolTypes, setDisplaySchoolTypes] = useState(false);
    const [displayGenderTypes, setDisplayGenderTypes] = useState(false);
    const [displayProviderTypes, setDisplayProviderTypes] = useState(false);

    // Advanced Filter (Can Read, Can Identiy Digit, ...)
    const [canAdd, setCanAdd] = useState("");
    const [canSubtract, setCanSubtract] = useState("");
    const [canMultiply, setCanMultiply] = useState("");
    const [canDivide, setCanDivide] = useState("");

    // Literacy Assessment & Math Assessmennt
    const [selectedLiteracyOption, setSelectedLiteracyOption] = useState("");
    const [selectedMathOption, setSelectedMathOption] = useState("");

    // Chart Dataset
    const [analysisData, setAnalysisData] = useState([]);

    // Option Handlers
    const handleTypeSelection = (selectedOption) => {
        setSelectedType(selectedOption);

        if (selectedOption.value === "School") {
            setDisplaySchoolTypes(true)
            setDisplayGenderTypes(false)
            setDisplayProviderTypes(false)
        }
        if (selectedOption.value === "Gender") {
            setDisplayGenderTypes(true)
            setDisplaySchoolTypes(false)
            setDisplayProviderTypes(false)
        }
        if (selectedOption.value === "Service_Provider") {
            setDisplayProviderTypes(true)
             setDisplayGenderTypes(false)
            setDisplaySchoolTypes(false)
        }
    }

    const handleLiteracyOptionChange = (selectedOption) => {
        setSelectedLiteracyOption(selectedOption);
    }

    const handleMathOptionChange = (selectedOption) => {
        setSelectedMathOption(selectedOption);
    }

    const handleAddOperationChange = (selectedOption) => {
        setCanAdd(selectedOption);
    }

    const handleSubtractOperationChange = (selectedOption) => {
        setCanSubtract(selectedOption);
    }

    const handleMultiplyOperationChange = (selectedOption) => {
        setCanMultiply(selectedOption);
    }

    const handleDivideOperationChange = (selectedOption) => {
        setCanDivide(selectedOption);
    }

    const handleAssessmentOptionChange = (selectedOption) => {
        setSelectedAssessmentType(selectedOption);
    }

    // Static Data
    const analysisOption = [
        {value: 'School', label:'Schools'},
        {value: 'Gender', label:'Gender'},
        {value: 'Service_Provider', label:'Service Provider'},
    ]

    const schoolOption = [
        {value: 'Treatment', label:'Treatment Schools'},
        {value: 'Control', label:'Control Schools'},
    ]

    const genderOption = [
        {value: 'Male', label:'Male'},
        {value: 'Female', label:'Female'},
    ]

    const providerOption = [
        {value: 'Save_The_Children', label:'Save The Children'},
        {value: 'Rising_Academy_Network', label:'Rising Academy Network'},
        {value: 'EducAid', label:'EducAid'},
        {value: 'World_Vision', label:'World Vision'},
        {value: 'National_Youth_Awareness', label:'National Youth Awareness Forum'},
    ]

    const literacyAssessmentOption = [
        {value: 'Beginner', label:'Beginner'},
        {value: 'Letter', label:'Letter'},
        {value: 'Paragraph', label:'Paragraph'},
        {value: 'Story', label:'Story'},
        {value: 'Word', label:'Word'},
    ]

    const mathAssessmentOption = [
        {value: 'Beginner', label:'Beginner'},
        {value: 'One_Digit', label:'One Digit'},
        {value: 'Two_digits', label:'Two Digits'},
        {value: 'Three_Digits', label:'Three Digits'},
    ]

    const assessmentType = [
        {value: 'Baseline Assessment', label: 'Baseline Assessment'},
        {value: 'Midterm Assessment', label: 'Midterm Assessment'},
    ]

    const operations = [
        {value: 'yes', label: 'Yes'},
        {value: 'no', label: 'No'},
    ]

    const fetchAnalysisData = () => {
        const generalParams = {}
        const advancedParams = {}
        const chartLabel = ""

        if(selectedType === ""){
            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select Analysis Type!',
                footer: 'HCD Incubator'
            })
        }

        if(selectedAssessmentType === ""){
            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select Assessment Type!',
                footer: 'HCD Incubator'
            })
        }

        if (displaySchoolTypes) {
            if(selectedSchoolType === ""){
                Swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select a School Type!',
                    footer: 'HCD Incubator'
                })
            }
            generalParams["type"] = selectedSchoolType.value
            chartLabel.concat(selectedSchoolType.label + ", ")
        }
        if (displayGenderTypes) {
           if(selectedGenderType === ""){
                Swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select a Gender!',
                    footer: 'HCD Incubator'
                })
            }
            generalParams["gender"] = selectedGenderType.value
            chartLabel.concat("Gender = " + selectedGenderType.value + ", ")
        }
        if (displayProviderTypes) {
            if(selectedProviderType === ""){
                Swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select a Gender!',
                    footer: 'HCD Incubator'
                })
            }
            generalParams["service_provider"] = selectedProviderType.value
            chartLabel.concat("Service Provider = " + selectedProviderType.value + ", ")
        }
        
        if (selectedLiteracyOption !== "") {
            advancedParams["literacy_assessment"] = selectedLiteracyOption.value
            chartLabel.concat("Literacy Assessment = " + selectedLiteracyOption.value + ", ")
        }
        if (selectedMathOption !== "") {
            advancedParams["math_assessment"] = selectedMathOption.value
            chartLabel.concat("Math Assessment = " + selectedMathOption.value + ", ")
        }
        
        if (canAdd.value === "yes") {
            console.log("Can Add: " + canAdd.value)
            advancedParams["addition"] = "Correct"
            chartLabel.concat("Can Add = " + ", ")
        }
        if (canSubtract.value === "yes") {
            advancedParams["subtraction"] = "Correct"
            chartLabel.concat("Can Subtract = " + ", ")
        }
        if (canMultiply.value === "yes") {
            advancedParams["multiplication"] = "Correct"
            chartLabel.concat("Can Multiply = " + ", ")
        }
        if (canDivide.value === "yes") {
            advancedParams["division"] = "Correct"
            chartLabel.concat("Can Divide = " + ", ")
        }

        const params = {
            general: generalParams,
            advanced: advancedParams,
            assement_type: selectedAssessmentType.value
        }

        console.log("Chart Label: " + chartLabel)
        
        axios.get(`${REACT_APP_URL}/get-analysis-data/`,{
                params: params
        })
            .then((response) => {     
                console.log(response.data)                                     
                setAnalysisData(response.data);   
                resetState()
                // Remove last Comma from Chart Label
                setChartLengend(chartLabel.substring(0, chartLabel.length - 1))
            })
            .catch((error) => {})
               
    }

    function resetState() {
        setCanAdd("");
        setCanDivide("");
        setCanMultiply("");
        setCanSubtract("");
        setSelectedLiteracyOption("");
        setSelectedMathOption("");
    }

    // Chart Config
    const options = {

        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5', 'Class 6'],
        },
        yaxis: {
            title: {
                text: 'Percentage'
            },
            min: 0,
            max: 100,
        },
        fill: {
            opacity: 1
        },
        title: {
            text: chartLegend
        }

    };


    return(
        <>
            <div className="container-fluid" style={{marginTop: "2%"}}>
                <div className="card p-4">
                    <div className="card-content">
                        <div className="grid">
                            <div className="row">
                                <div className="cell-md-7">
                                    <div className="row">
                                        <div className="cell-md-12">
                                            <h5>General Filter</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-leader2">Analysis Type</p>
                                            <Select options={analysisOption} value={selectedType} onChange={handleTypeSelection}/>
                                        </div>
                                        <div className="cell-md-6">
                                            {displaySchoolTypes && 
                                            <><p className="text-leader2">Select School Type </p>
                                            {selectedType.value == "School" && <Select options={schoolOption} value={selectedSchoolType} onChange={setSelectedSchoolType}/>}                                   
                                            </>}

                                            {displayGenderTypes && 
                                            <><p className="text-leader2">Select Gender </p>
                                            {selectedType.value == "Gender" && <Select options={genderOption} value={selectedGenderType} onChange={setSelectedGenderType}/>}                                    
                                            </>}

                                            {displayProviderTypes && 
                                            <><p className="text-leader2">Select Service Provider</p>
                                            {selectedType.value == "Service_Provider" && <Select options={providerOption} value={selectedProviderType} onChange={setSelectedProviderType}/>}                                     
                                        
                                            </>}
                                        </div>                           
                                       
                                    </div>                           
                                    <div className="row">
                                            <div className="cell-md-6">
                                                <p className="text-leader2">Select Assessment Type</p>
                                                <Select options={assessmentType} value={selectedAssessmentType} onChange={handleAssessmentOptionChange}/>
                                            </div>
                                            <div className="cell-md-6">
                                                
                                            </div>
                                    </div>                                   
                                    </div>  
                                <div className="cell-md-5">
                                    <div className="row">
                                        <div className="cell-md-12">
                                            <h5>Advanced Filter</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-leader2">Literacy Assessment</p>
                                            <Select options={literacyAssessmentOption} value={selectedLiteracyOption} onChange={handleLiteracyOptionChange}/>
                                        </div>
                                        <div className="cell-md-6">
                                            <p className="text-leader2">Math Assessment</p>
                                            <Select options={mathAssessmentOption} value={selectedMathOption} onChange={handleMathOptionChange}/>
                                        </div>
                                    </div>  
                                    <div className="row">
                                        <div className="cell-md-6"> 
                                            <p className="text-leader2">Can Add</p>
                                            <Select options={operations} value={canAdd} onChange={handleAddOperationChange}/>
                                        </div>
                                        <div className="cell-md-6">
                                            <p className="text-leader2">Can Subtract</p>
                                            <Select options={operations} value={canSubtract} onChange={handleSubtractOperationChange}/>                                           
                                        </div>
                                    </div>  
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-leader2">Can Multiply</p>
                                            <Select options={operations} value={canMultiply} onChange={handleMultiplyOperationChange}/>                                            
                                        </div>
                                        <div className="cell-md-6">
                                            <p className="text-leader2">Can Divide</p>
                                            <Select options={operations} value={canDivide} onChange={handleDivideOperationChange}/>                                             
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="cell-md-12">
                                    <p>
                                        <button className="button fg-white" style={{backgroundColor: "#17162c"}} onClick={fetchAnalysisData}>Analyze</button>
                                    </p>
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card p-4">
                    <div className="card-content">
                        <div className="grid">                             
                            <div className="row">
                                {                                   
                                    analysisData.map((item, index) => {
                                        return(
                                           <div className="cell-md-12" key={index}>                                              
                                                <h6>{chartLegend}</h6>
                                                <ReactApexChart options={options} series={item.data} type="bar" height={350} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
};

export default AdvancedAnalysis;