import React from 'react'
import Dashboard from "../pages/Dashboard";
import AggregatedMathOperations from "./AggregatedMathOperations";
import AggregatedNumeracy from "./AggregatedNumeracy";

const SchoolsComparison = () => {

    return(

        <Dashboard>
            <div className="container-fluid mt-4">
                <div className="grid">
                    <div className="row">
                        <div className="cell-md-12">
                            <div className="card p-4">
                                <div className="card-content">
                                    <h2 className="text-center">Schools Comparison</h2>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Student That Can Identify Digits</p>
                                            <AggregatedNumeracy/>
                                        </div>
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Students That Can Do Math Operations</p>
                                            <AggregatedMathOperations/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}

export default SchoolsComparison;