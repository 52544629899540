import Dashboard from "../pages/Dashboard";
import EventCounter from "./EventCounter";


const MonitoringAndEvaluation = () => {

    return(

        <Dashboard>
            <div className="container-fluid" style={{marginTop:"15%"}}>
                <div className="row">
                    <div className="cell-md-12">
                        <h3 className="text-center">Coming Soon</h3>
                        <EventCounter/>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}
export default MonitoringAndEvaluation;