import React, {useEffect, useState} from  'react';
import axios from "axios";
import {REACT_APP_URL} from "../../env";
import Chart from 'react-apexcharts';

const EndlineNumeracyOverall = () => {

    const [data, setData] = useState("");

    const fetchData = () => {

        axios.get(`${REACT_APP_URL}/endline-numeracy-overall/`)
        
        .then((response) =>{

            setData(response.data);

        })
        .catch((error) => {

            console.log("Error: " + error);
        });
    }

    const series = [
        {
            name: "Percentage",
            data: Array.from(data)
        }
    ]

    const options = {
        chart: {
          type: 'area',
          height: 350,
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return val 
        },
        },
        stroke: {
          curve: 'straight'
        },
        fill: {
          opacity: 0.8,
          type: 'pattern',
          pattern: {
            style: ['verticalLines', 'horizontalLines'],
            width: 5,
            height: 6
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 9
          }
        },
        tooltip: {
          intersect: true,
          shared: false
        },
       
        yaxis: {
            //show: false,
          title: {
            text: 'Percentage'
          },
        }
    };

    useEffect(() => {

        fetchData();
    }, []);


    return(

        <div id="endline-chart-n-overall">
            <Chart options={options} series={series} type="area" height={350} />
        </div>

    );
}

export default EndlineNumeracyOverall;