import React, {useState, useEffect} from 'react'
import Dashboard from "../pages/Dashboard";
import Select from "react-select";
import axios from 'axios';
import Swal from 'sweetalert'
import ReactApexChart from 'react-apexcharts';
import {REACT_APP_URL} from "../env";

const CompareServiceProviders = () => {

    const [sp, setSelectSP] = useState([]);

    const [assessmentType, setAssessmentType] = useState("");

    const [assessment, setAssessment] = useState("");

    const [compareData, setCompareData] = useState([]);



    const serviceProviders = [

        {value: 'EducAid', label: 'EducAid'},
        {value: 'NYAFSL', label: 'National Youth Awareness Forum'},
        {value: 'Rising Academy Network', label: 'Rising Academy Network'},
        {value: 'Save the Children', label: 'Save The Children'},
        {value: 'World Vision International', label: 'World Vision International'},
    ]

    const assessmentTypeOptions = [

        {value: 'Baseline Assessment', label: 'Baseline Assessment'},
        {value: 'Midterm Assessment', label: 'Midterm Assessment'},
    ]

    const assessmentOptions = [
        {value:'Literacy', label: 'Literacy'},
        {value: 'Numeracy', label: 'Numeracy'},
        {value:'Math Operations', label: 'Math Operations'},
    ]

    const handleOnChangeSP = (selectedOptions) => {

        setSelectSP(selectedOptions)
    };

    const handleAssessmentTypeChange = (selectedOption) => {

        setAssessmentType(selectedOption);
    }

    const handleAssessmentChange = (selectedOption) => {

        setAssessment(selectedOption);

    }

    const getComparison = () => {

        const sp_names = [];

        if(!sp || sp === undefined || sp.length == 0){

            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Select Service Providers!',
                footer: 'HCD Incubator'
            })
        }
        else if(sp.length == 1){

            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Select At Least Two Service Providers!',
                footer: 'HCD Incubator'
            })
        }
        else if(sp == ""){

            alert("error");
        }
        else if(assessmentType == ""){

            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Select An Assessment Type!',
                footer: 'HCD Incubator'
            })
        }
        else if(assessment == ""){

            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Select An Assessment!',
                footer: 'HCD Incubator'
            })
        }
        else{

            for(let i = 0; i < sp.length; i++){

                sp_names.push(sp[i].value)
            }

            axios.get(`${REACT_APP_URL}/compare-assessments-by-service-providers/`,{
                params:{
                    service_providers:sp_names,
                    assessment_type:assessmentType.value,
                    assessment: assessment.value
                }
            })
                .then((response) => {

                    setCompareData(response.data);
                })
                .catch((error) => {

                    console.log(error)
                });
        }


    }

    const options = {

        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5', 'Class 6'],
        },
        yaxis: {
            title: {
                text: 'Percentage'
            },
            min: 0,
            max: 100,
        },
        fill: {
            opacity: 1
        },

    };

    return(

        <Dashboard>
            <div className="container-fluid" style={{marginTop: "2%"}}>
                <div className="card p-4">
                    <div className="card-content">
                        <div className="grid">
                            <div className="row">
                                <div className="cell-md-12">
                                    <p className="text-leader2">Select Service Providers</p>
                                    <Select options={serviceProviders} isMulti onChange={handleOnChangeSP} value={sp}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-6">
                                    <p className="text-leader2">Select Assessment Type</p>
                                    <Select options={assessmentTypeOptions} value={assessmentType} onChange={handleAssessmentTypeChange}></Select>
                                </div>
                                <div className="cell-md-6">
                                    <p className="text-leader2">Select Assessment Option</p>
                                    <Select options={assessmentOptions} value={assessment} onChange={handleAssessmentChange}></Select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-12">
                                    <p>
                                        <button className="button fg-white" style={{backgroundColor: "#17162c"}} onClick={getComparison}>COMPARE</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card container-fluid">
                    <div className="card-content">
                        <div className="grid">
                            <div className="row">
                                {
                                    compareData.map((item, index) => {
                                        return(
                                            item.map((data, i) => {
                                                return(

                                                    <div className="cell-md-6" key={i}>
                                                        <h4>{data.sp_name}</h4>
                                                        <ReactApexChart options={options} series={data.data} type="bar" height={350} />
                                                    </div>
                                                )
                                            })
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}

export default CompareServiceProviders;