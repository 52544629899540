import React, {useEffect, useState} from 'react';
import axios from "axios";
import {REACT_APP_URL} from "../../../env";
import Chart from 'react-apexcharts';

const LiteracyOverall = () => {

    const [data, setData] = useState("");

    const fetchData = () => {

        axios.get(`${REACT_APP_URL}/literacy-level-overall-sp/`, {
            params:{
                "service_provider": "EducAid"
            }
        })
        
        .then((response) =>{

            setData(response.data);

        })
        .catch((error) => {

            console.log("Error: " + error);
        });
    }

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Beginner', 'Letter', 'Word', 'Paragraph', 'Story'],
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "%"
                }
            }
        }
    }

    useEffect(() => {

        fetchData();

    }, []);


    return(

        
        <div id="ds-chart-ll-overall">
            <Chart options={options} series={data} type="bar" height={350} width="100%"/>
        </div>

    );
}

export default LiteracyOverall;