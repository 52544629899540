import React, {useEffect, useState, useRef} from 'react';
import ReactMapGL, {Marker, NavigationControl, Popup} from 'react-map-gl';
import {TK} from '../env';
import Select from "react-select";
import {REACT_APP_URL} from "../env";
import TreatmentIcon from "./icons/TreatmentIcon";
import ControlIcon from "./icons/ControlIcon";
import axios from 'axios';
import {Link} from "react-router-dom";
import {useSize} from 'react-hook-size';
import urlSlug from 'url-slug';


const navControlStyle= {
    right: 10,
    top: 0
};

const IndicatorMap = () => {

    const [schoolsData, setSchoolsData] = useState([]);

    const mapContainer = useRef(null);

    const {width, height} = useSize(mapContainer);

    const [viewport, setViewport] = useState({
        latitude: 8.3289637,
        longitude: -12.214362,
        zoom: 7,
        width: width || 630,
        height:width || 630,
    });

    const [schoolType, setSchoolType] = useState("");
    const [popupInfo, setPopupInfo] = useState(null);


    const schoolOptions = [
        {value: 'All', label: 'All'},
        {value: 'Treatment Schools', label: 'Treatment Schools'},
        {value: 'Control Schools', label: 'Control Schools'},
    ]

    const handleChange = (selectedOption) => {

        setSchoolType(selectedOption);
    }

    const fetchData = () => {

        axios.get(`${REACT_APP_URL}/list-all-schools/`,{

            params:{
                school_type: schoolType.value,
            }
        })
            .then((response) => {

                setSchoolsData(response.data);
            })
            .catch((error) => {

                console.log("Error: " + error);
            })
    }

    useEffect(() => {

        fetchData();

    }, [])

    useEffect(() => {

        fetchData();

    }, [schoolType])

    useEffect(() => {
        if(width && height){
            setViewport((viewport) => ({
                ...viewport,
                width,
                height
            }));
        }
    }, [width, height]);


    return(
        <div className="row">
            <div className="cell-md-12">
                <div className="card p-4" ref={mapContainer}>
                    <div className="card-content">
                        <ReactMapGL
                            {...viewport}
                            className='mapboxgl-map'
                            onViewportChange={setViewport}
                            mapStyle="mapbox://styles/mapbox/light-v9"
                            mapboxApiAccessToken={TK}
                        >
                            {schoolsData.map((school, index) => {
                                if(school.school_type === "Treatment"){
                                    return(
                                        <Marker key={`marker-${index}`} longitude={school.long} latitude={school.lat}>
                                            <TreatmentIcon size={20} onClick={() => {setPopupInfo(school)}}/>
                                        </Marker>
                                    )
                                }
                                else {

                                    return(
                                        <Marker key={`marker-${index}`} longitude={school.long} latitude={school.lat}>
                                            <ControlIcon size={20} onClick={() => {setPopupInfo(school)}}/>
                                        </Marker>
                                    )
                                }

                            })}
                            {popupInfo && (
                                <Popup
                                    tipSize={5}
                                    anchor="top"
                                    longitude={popupInfo.long}
                                    latitude={popupInfo.lat}
                                    closeOnClick={false}
                                    onClose={() => {setPopupInfo(null)}}
                                >
                                    <div>
                                        <h6 className="text-center" style={{fontSize:"13px"}}>SCHOOL NAME</h6>
                                        <p className="text-center" style={{fontSize:"10px"}}>{popupInfo.school_name}</p>
                                        <h6 className="text-center" style={{fontSize:"13px"}}>EMIS CODE</h6>
                                        <p className="text-center" style={{fontSize:"10px"}}>{popupInfo.emis_code}</p>
                                        <h6 className="text-center" style={{fontSize:"13px"}}>DISTRICT</h6>
                                        <p className="text-center" style={{fontSize:"10px"}}>{popupInfo.district}</p>
                                        <h6 className="text-center" style={{fontSize:"13px"}}>CHIEFDOM</h6>
                                        <p className="text-center" style={{fontSize:"10px"}}>{popupInfo.chiefdom}</p>
                                        <p className="text-center"><Link to={`/school/${urlSlug(popupInfo.school_name)}/${popupInfo.emis_code}`} className="button fg-white" style={{backgroundColor:"#17172c"}}>View Analysis</Link></p>
                                    </div>
                                </Popup>
                            )}
                            <NavigationControl style={navControlStyle} />
                        </ReactMapGL>
                        <div className="control-panel">
                            <h5>Select School Type</h5>
                            <Select options={schoolOptions} value={schoolType} onChange={handleChange}/>
                            <br/>
                            <p>
                                <span className="dot-treatment"></span> Treatment Schools
                            </p>
                            <p>
                                <span className="dot-control"></span> Control Schools
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default IndicatorMap;