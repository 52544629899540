import React, {useEffect, useState} from "react";
import Dashboard from "../pages/Dashboard";
import Select from 'react-select';
import axios from 'axios';
import {REACT_APP_URL} from "../env";
import Swal from "sweetalert";
import ReactApexChart from "react-apexcharts";

const CompareSchools = () => {

    const [firstSelect, setFirstSelect] = useState("");
    const [secondSelect, setSecondSelect] = useState("");
    const [aType, setAType] = useState("");
    const [aOption, setAOption] = useState("");
    const [firstSchool, setFirstSchool] = useState("");
    const [secondSchool, setSecondSchool] = useState("");

    const [displayFirst, setDisplayFirst] = useState(false);
    const [displaySecond, setDisplaySecond] = useState(false);

    const [schoolOptions, setSchoolOptions] = useState([]);
    const [schoolOptionsSec, setSchoolOptionsSec] = useState([]);

    const [compareData, setCompareData] = useState([]);

    const defaultOption = [

        {value: 'Treatment Schools', label:'Treatment Schools'},
        {value: 'Control Schools', label:'Control Schools'},
    ]

    const assessmentType = [

        {value: 'Baseline Assessment', label: 'Baseline Assessment'},
        {value: 'Midterm Assessment', label: 'Midterm Assessment'},
    ]

    const assessmentOptions = [
        {value:'Literacy', label: 'Literacy'},
        {value: 'Numeracy', label: 'Numeracy'},
        {value:'Math Operations', label: 'Math Operations'},
    ]

    const handleFirstChange = (selectedOption) => {

        setFirstSelect(selectedOption);

        setDisplayFirst(true)

    }

    const handleSecondChange = (selectedOption) => {

        setSecondSelect(selectedOption);

        setDisplaySecond(true);
    }

    const handleATypeChange = (selectedOption) => {

        setAType(selectedOption);
    }

    const handleAOptionChange = (selectedOption) => {

        setAOption(selectedOption);
    }

    const handlefirstSchoolChange = (selectedOption) => {

        setFirstSchool(selectedOption);
    }
    const handlesecondSchoolChange = (selectedOption) => {

        setSecondSchool(selectedOption);
    }

    useEffect(() => {

        if(firstSelect.value === "Treatment Schools"){

            setFirstSchool("");

            axios.get(`${REACT_APP_URL}/get-schools/`, {
                params:{
                    school_type: 'Treatment'
                }
            })
                .then((response) => {


                    setSchoolOptions(response.data)
                })
                .catch((error) => {

                })
        }
        else if(firstSelect.value === "Control Schools"){
            setFirstSchool("");
            axios.get(`${REACT_APP_URL}/get-schools/`, {
                params:{
                    school_type: 'Control'
                }
            })
                .then((response) => {

                    setSchoolOptions(response.data)

                })
                .catch((error) => {

                })
        }

    },[firstSelect])

    useEffect(() => {

        if(secondSelect.value === "Treatment Schools"){

            setSecondSchool("");

            axios.get(`${REACT_APP_URL}/get-schools/`, {
                params:{
                    school_type: 'Treatment'
                }
            })
                .then((response) => {


                    setSchoolOptionsSec(response.data)
                })
                .catch((error) => {

                })
        }
        else if(secondSelect.value === "Control Schools"){
            setSecondSchool("");
            axios.get(`${REACT_APP_URL}/get-schools/`, {
                params:{
                    school_type: 'Control'
                }
            })
                .then((response) => {

                    setSchoolOptionsSec(response.data)

                })
                .catch((error) => {

                })
        }

    },[secondSelect])

    const getComparison = () => {

        if(firstSelect === ""){
            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a type for the first!',
                footer: 'HCD Incubator'
            })
        }
        else if(secondSelect === ""){
            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a type for the second!',
                footer: 'HCD Incubator'
            })
        }
        else if(firstSchool === ""){
            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select the first school!',
                footer: 'HCD Incubator'
            })
        }
        else if(secondSchool === ""){

            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select the second school!',
                footer: 'HCD Incubator'
            })
        }
        else if(aType === ""){
            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select an assessment type!',
                footer: 'HCD Incubator'
            })
        }
        else if(aOption === ""){
            Swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select an assessment option!',
                footer: 'HCD Incubator'
            })
        }
        else{

            axios.get(`${REACT_APP_URL}/get-comparison-for-schools/`,{
                params:{

                    first_school: firstSchool.value,
                    second_school: secondSchool.value,
                    assessment_type: aType.value,
                    assessment_option: aOption.value
                }
            })
                .then((response) => {

                    setCompareData(response.data);

                })
                .catch((error) => {


                })
        }
    }

    const options = {

        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5', 'Class 6'],
        },
        yaxis: {
            title: {
                text: 'Percentage'
            },
            min: 0,
            max: 100,
        },
        fill: {
            opacity: 1
        },

    };


    return(

        <Dashboard>
            <div className="container-fluid" style={{marginTop: "2%"}}>
                <div className="card p-4">
                    <div className="card-content">
                        <div className="grid">
                            <div className="row">
                                <div className="cell-md-6">
                                    <p className="text-leader2">Select Type</p>
                                    <Select options={defaultOption} value={firstSelect} onChange={handleFirstChange}/>
                                </div>
                                <div className="cell-md-6">
                                    <p className="text-leader2">Select Type</p>
                                    <Select options={defaultOption} value={secondSelect} onChange={handleSecondChange}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-6">
                                    {displayFirst && <><p className="text-leader2">Select {firstSelect.value}</p><Select options={schoolOptions} value={firstSchool} onChange={handlefirstSchoolChange}/></>}
                                </div>
                                <div className="cell-md-6">
                                    {displaySecond && <><p className="text-leader2">Select {secondSelect.value}</p><Select options={schoolOptionsSec} value={secondSchool} onChange={handlesecondSchoolChange}/></>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-6">
                                    <p className="text-leader2">Select Assessment Type</p>
                                    <Select options={assessmentType} value={aType} onChange={handleATypeChange}/>
                                </div>
                                <div className="cell-md-6">
                                    <p className="text-leader2">Select Assessment Option</p>
                                    <Select options={assessmentOptions} value={aOption} onChange={handleAOptionChange}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-12">
                                    <p>
                                        <button className="button fg-white" style={{backgroundColor: "#17162c"}} onClick={getComparison}>COMPARE</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card p-4">
                    <div className="card-content">
                        <div className="grid">
                            <div className="row">
                                {
                                    compareData.map((item, index) => {
                                        return(
                                            item.map((data, i) => {
                                                return(

                                                    <div className="cell-md-6" key={i}>
                                                        <h6>{data.school_name}</h6>
                                                        <ReactApexChart options={options} series={data.data} type="bar" height={350} />
                                                    </div>
                                                )
                                            })
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
};

export default CompareSchools;