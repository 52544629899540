import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import {REACT_APP_URL} from '../../../env';

const MathOperations = (props) => {

    const [data, setData] = useState([]);

    const fetchData = () => {
    
        axios.get(`${REACT_APP_URL}/math-operations-sp/`,{
            params:{
                "service_provider": "EducAid"
            }
        })
        
        .then((response) =>{
    
            setData(response.data);
    
        })
        .catch((error) => {
    
            console.log("Error: " + error);
        });
    }

    useEffect(() => {

        fetchData();

    }, [])

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Addition', 'Subtraction', 'Multiplication', 'Division'],
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "%"
                }
            }
        }
    }

    return(

        <div id="chart-treatment-math-operations">
            <ReactApexChart options={options} series={data} type="bar" height={350} />
        </div>
    );
}

export default MathOperations;