import React, { useState, useEffect} from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import Select from 'react-select';
import {REACT_APP_URL} from '../../env';


const StudentPerLevel = () => {

    const [data, setData] = useState("");

    const [district, setDistrict] = useState([]);

    const [dist, setDist] = useState("");

    const [filter, setFilter] = useState("Western Urban");

    const handleChange = (selectedOption) => {

        setDist(selectedOption);

        setFilter(selectedOption.value);

    }

    const fetchData = () => {
    
        axios.get(`${REACT_APP_URL}/get-student-assessed-per-district/?district=${filter}`)
        
        .then((response) =>{
    
            setData(response.data);
    
        })
        .catch((error) => {
    
            console.log("Error: " + error);
        });
    }

    const fetchDistrict = () => {

        axios.get(`${REACT_APP_URL}/get-all-districts/`)
        
        .then((response) =>{
    
            setDistrict(response.data);
    
        })
        .catch((error) => {
    
            console.log("Error: " + error);
        });
    }

    const options = {

        chart: {
            height: 350,
            type: 'bar',
            id: 'studentPerDistrict',
            toolbar: {
                show: true
            },
            zoom: {
                enabled: false
            },
            events: {
              click: function(chart, w, e) {
                // console.log(chart, w, e)
              }
            }
        },
        colors: ['#F44336', '#0d47a1', '#004d40', '#E91E63', '#9C27B0', '#3e2723'],
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: ["Class 1", "Class 2", "Class 3", "Class 4", "Class 5", "class 6"],
            labels: {
                style: {
                  colors: ['#F44336', '#0d47a1', '#004d40', '#E91E63', '#9C27B0', '#3e2723'],
                  fontSize: '12px'
                }
            }
        },
    }; 

    const series = [
        {
          name: "Total Students",
          data: Array.from(data)
        }
    ];

    useEffect(() => {

            fetchDistrict();
    
            fetchData();
    }, []);

    useEffect(() => {

        fetchData();

    }, [filter]);

    return(

        <div id="studentPerDistrict">
            <Select value={dist} options={district} onChange={handleChange} placeholder="Western Urban"/>
            <Chart options={options} series={series} type="line" height={306} />
        </div>
    );
}

export default StudentPerLevel;


