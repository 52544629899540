import React from 'react';
import Dashboard from "../pages/Dashboard";
import LiteracyOverall from "./sps/stc/LiteracyOverall";
import NumeracyOverall from "./sps/stc/NumeracyOverall";
import MathOperations from "./sps/stc/MathOperations";

const Savethechildren = () => {

    return(

        <Dashboard>
            <div className="container-fluid">
                <div className="grid">
                    <div className="row">
                        <div className="cell-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="text-center">Save the Children</h3>
                                </div>
                                <div className="card-content p-4">
                                    <h4>Innovative Project Description</h4>
                                    <p style={{textAlign:"justify", textJustify:"inter-word"}} className="text-leader">
                                        The Literacy and Numeracy Boost Project will train teachers, as well as parents and community members, to better understand and support their children’s learning process. Literacy and numeracy skills will be boosted through formal and non-formal education in the schools, in homes, and in communities.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="cell-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="text-center">Analysis</h3>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Literacy Level Attained (Overall)</p>
                                            <LiteracyOverall/>
                                        </div>
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">
                                                Percentage of Student That Can Identify Digits
                                            </p>
                                            <NumeracyOverall/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Students That Can Do Math Operations</p>
                                            <MathOperations/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}

export default Savethechildren;