import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import { RadioGroup, Radio } from 'react-radio-group';
import {REACT_APP_URL} from '../../env';

const EndlineLiteracyLevelGrade = () => {

    const [data, setData] = useState([]);

    const [toggle, setToggle] = useState("Bar");

    const fetchData = () => {
    
        axios.get(`${REACT_APP_URL}/endline-literacy-per-level/`)
        
        .then((response) =>{
    
            setData(response.data);
    
        })
        .catch((error) => {
    
            console.log("Error: " + error);
        });
    }

    useEffect(() => {

        fetchData();

    }, [])

/*
    const options = {

        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%',
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
        }],
        plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '90%',
              endingShape: 'rounded'
            }
        },
        tooltip: {
            y: {
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return value
                }
              
            },
        },
        xaxis: {
            categories: ['Class 1', 'Class 2', 'Class 3', 'Class 4','Class 5', 'Class 6'],
            tickPlacement: 'between',
        },
        legend: {
            position: 'right',
            offsetY: 40
        },
        fill: {
            opacity: 1
        }
        
    };
*/
const options = {

    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5', 'Class 6'],
    },
    yaxis: {
        title: {
          text: 'Percentage'
        }
    },
    fill: {
        opacity: 1
    },
    
    
};
    const lineOptions = {

        chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            }
        },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
        }],
        tooltip: {
            y: {
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return value
                }
              
            },
        },
        xaxis: {
            categories: ['Class 1', 'Class 2', 'Class 3', 'Class 4','Class 5', 'Class 6'],
            tickPlacement: 'between',
        },
        legend: {
            position: 'right',
            offsetY: 40
        },
        fill: {
            opacity: 1
        }
    }

    const handleRadioChange = (value) => {

        setToggle(value)
    }
    

    return(

        <div id="chart">
            <p className="text-center">
                <RadioGroup name="endline-chart-literacy-grade" selectedValue={toggle} onChange={handleRadioChange}>
                    <Radio value="Bar" />Bar
                    <Radio value="Line" />Line
                    <Radio value="Radar" />Radar
                </RadioGroup>
            </p>
            {toggle === "Bar" && <ReactApexChart options={options} series={data} type="bar" height={350} />}
            {toggle === "Line" && <ReactApexChart options={lineOptions} series={data} type="line" height={350} />}
            {toggle === "Radar" && <ReactApexChart options={options} series={data} type="radar" height={350} />}
        </div>
    );
}

export default EndlineLiteracyLevelGrade;