import 'metro4-dist/css/metro-all.min.css';
import 'metro4-dist/js/metro.min';
import './Dashboard.css';
import Logo from '../images/logo-simple.svg';
import {Link} from 'react-router-dom';

const Dashboard = (props) => {

    return(

        <div data-role="navview" data-toggle="#paneToggle" data-expand="xl" data-compact="lg" data-active-state="true">
            <div className="navview-pane" style={{backgroundColor:"#000"}}>
                <div className="d-flex flex-align-center" style={{backgroundColor: "#000"}}>
                    <button className="pull-button m-0 bg-darkCyan-hover">
                        <span className="mif-menu fg-white"></span>
                    </button>
                    <h2 className="text-light m-0 fg-white pl-7" style={{lineHeight: "52px"}}>DSTI</h2>
                </div>
                <ul className="navview-menu mt-4" id="side-menu" style={{backgroundColor:"#000"}}>
                    <li className="item-header fg-white">MAIN NAVIGATION</li>
                    <li>
                        <a href="/">
                            <span className="icon"><span className="mif-meter"></span></span>
                            <span className="caption">Dashboard</span>
                        </a>
                    </li>
                    <li className="item-header fg-white">COMPARISON</li>
                    <li>
                        <Link to="/baseline-and-midterm/compare-district">
                            <span className="icon"><span className="mif-map"></span></span>
                            <span className="caption">Districts</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/compare-schools">
                            <span className="icon"><span className="mif-library"></span></span>
                            <span className="caption">Schools</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/compare-service-providers">
                            <span className="icon"><span className="mif-local-service"></span></span>
                            <span className="caption">Service Providers</span>
                        </Link>
                    </li>
                    <li className="item-header fg-white">AGGREGATED ANALYSIS</li>
                    <li>
                        <Link to="/schools-comparison">
                            <span className="icon"><span className="mif-chart-bars"></span></span>
                            <span className="caption">Schools Comparisons</span>
                        </Link>
                    </li>
                    {/*
                    <li>
                        <Link to="/service-providers-aggregated">
                            <span className="icon"><span className="mif-chart-bars"></span></span>
                            <span className="caption">Service Providers</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/">
                            <span className="icon"><span className="mif-chart-bars"></span></span>
                            <span className="caption">Districts</span>
                        </Link>
                    </li>
                    */}
                    <li>
                        <Link to="/treatment-schools-aggregated">
                            <span className="icon"><span className="mif-chart-bars"></span></span>
                            <span className="caption">Treatment Schools</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/control-schools-aggregated">
                            <span className="icon"><span className="mif-chart-bars"></span></span>
                            <span className="caption">Control Schools</span>
                        </Link>
                    </li>
                    <li className="item-header fg-white">MONITORING</li>
                    <li>
                        <Link to="/monitoring-and-evaluation">
                            <span className="icon"><span className="mif-clipboard"></span></span>
                            <span className="caption">Monitoring and Evaluation</span>
                        </Link>
                    </li>
                    {/*
                    <li>
                        <Link to="/monitoring-and-evaluation">
                            <span className="icon"><span className="mif-file-empty"></span></span>
                            <span className="caption">SPs Monthly Reports</span>
                        </Link>
                    </li>
                    */}
                </ul>

                <div className="w-100 text-center text-small data-box p-2 border-top bd-grayMouse"
                     style={{position: "absolute", bottom: 0}}>
                    <div>&copy; 2021. Education Innovation Challenge.</div>
                    <div>Brought to you by DSTI.</div>
                </div>
            </div>

            <div className="navview-content h-100">
                <div data-role="appbar" className="pos-absolute fg-white" style={{backgroundColor:"#17172c"}}>
                    <a href="#" className="app-bar-item d-block d-none-lg" id="paneToggle">
                        <span className="mif-menu"></span>
                    </a>
                    <a href="/" className="brand ml-auto">
                        <img src={Logo} alt="dsti_logo.svg" style={{width: '48px'}}/> &nbsp;<span className="brand-text">Education Innovation Challenge</span>
                    </a>
                    <div className="app-bar-container ml-auto">
                        <div className="app-bar-container">
                        </div>
                    </div>
                </div>
                <div id="content-wrapper" className="content-inner h-100" style={{overflowY: "auto"}}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Dashboard;