import React from 'react';
import Dashboard from "../pages/Dashboard";
import LiteracyOverall from "./sps/educaid/LiteracyOverall";
import NumeracyOverall from "./sps/educaid/NumeracyOverall";
import MathOperations from "./sps/educaid/MathOperations";


const Educaid = () => {

    return(

        <Dashboard>
            <div className="container-fluid">
                <div className="grid">
                    <div className="row">
                        <div className="cell-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="text-center">EducAid</h3>
                                </div>
                                <div className="card-content p-4">
                                    <h4>Innovative Project Description</h4>
                                    <p style={{textAlign:"justify", textJustify:"inter-word"}} className="text-leader">
                                        This innovation will engage and collaborate with school stakeholders – teachers, parent-bodies, school leaders, and education authorities –  to establish respectful schools focused on high cognitive & non-cognitive student outcomes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="cell-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="text-center">Analysis</h3>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Literacy Level Attained (Overall)</p>
                                            <LiteracyOverall/>
                                        </div>
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">
                                                Percentage of Student That Can Identify Digits
                                            </p>
                                            <NumeracyOverall/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Students That Can Do Math Operations</p>
                                            <MathOperations/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}

export default Educaid;