import React, {useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import {REACT_APP_URL} from "../env";

const AggregatedMathOperations = () => {

    const [data, setData] = useState([]);

    const fetchData = () => {

        axios.get(`${REACT_APP_URL}/get-aggregated-analysis-for-math-operations/`)

            .then((response) =>{

                setData(response.data);

            })
            .catch((error) => {

                console.log("Error: " + error);
            });
    }

    useEffect(() => {

        fetchData();

    }, [])

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Can Perform Addition', 'Can Perform Subtraction', ' Can Perform Multiplication', 'Can Perform Division'],
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "%"
                }
            }
        }
    }

    return(

        <div id="chart-aggregated-math-operations">
            <ReactApexChart options={options} series={data} type="bar" height={350} />
        </div>
    )
}

export default AggregatedMathOperations;