import React from 'react';
import Dashboard from "../pages/Dashboard";
import LiteracyOverall from "./sps/wvi/LiteracyOverall";
import NumeracyOverall from "./sps/wvi/NumeracyOverall";
import MathOperations from "./sps/wvi/MathOperations";


const WorldVision = () => {

    return(

        <Dashboard>
            <div className="container-fluid">
                <div className="grid">
                    <div className="row">
                        <div className="cell-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="text-center">World Vision International</h3>
                                </div>
                                <div className="card-content p-4">
                                    <h4>Innovative Project Description</h4>
                                    <p style={{textAlign:"justify", textJustify:"inter-word"}} className="text-leader">
                                        Unlock Literacy and Numeracy is an innovative, evidence-based approach that aims to improve teaching methods for classroom reading and maths by engaging students, families and communities in reading and maths activities outside of school, leveraging on digital technologies. The mode of instruction and learning will be through technology devices such as laptops, projectors and tablets.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="cell-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="text-center">Analysis</h3>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Literacy Level Attained (Overall)</p>
                                            <LiteracyOverall/>
                                        </div>
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">
                                                Percentage of Student That Can Identify Digits
                                            </p>
                                            <NumeracyOverall/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Students That Can Do Math Operations</p>
                                            <MathOperations/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}

export default WorldVision;