import React from 'react';
import Dashboard from "../pages/Dashboard";
import LiteracyOverall from "./sps/ran/LiteracyOverall";
import NumeracyOverall from "./sps/ran/NumeracyOverall";
import MathOperations from "./sps/ran/MathOperations";

const RisingAcademy = () => {

    return(

        <Dashboard>
            <div className="container-fluid">
                <div className="grid">
                    <div className="row">
                        <div className="cell-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="text-center">Rising Academy Network</h3>
                                </div>
                                <div className="card-content p-4">
                                    <h4>Innovative Project Description</h4>
                                    <p style={{textAlign:"justify", textJustify:"inter-word"}} className="text-leader">
                                        This innovation will introduce five essential success factors (ESFs) to teaching and learning in government schools: innovative curriculum and pedagogy, coaching of coaches, leading learning, oversight & data collection, along with targeted remedial programmes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="cell-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="text-center">Analysis</h3>
                                    <div className="row">
                                       <div className="cell-md-6">
                                        <p className="text-center text-leader2">Percentage of Literacy Level Attained (Overall)</p>
                                          <LiteracyOverall/>
                                       </div>
                                       <div className="cell-md-6">
                                            <p className="text-center text-leader2">
                                                Percentage of Student That Can Identify Digits
                                            </p>
                                           <NumeracyOverall/>
                                       </div>
                                    </div>
                                    <div className="row">
                                        <div className="cell-md-6">
                                            <p className="text-center text-leader2">Percentage of Students That Can Do Math Operations</p>
                                            <MathOperations/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}

export default RisingAcademy;