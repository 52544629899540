import React, {useEffect, useState} from 'react';
import axios from "axios";
import {REACT_APP_URL} from "../env";
import Chart from 'react-apexcharts';

const BaselineMidtermLiteracy = () => {

    const [data, setData] = useState("");

    const fetchData = () => {

        axios.get(`${REACT_APP_URL}/literacy-baseline-midterm-comparison/`)

            .then((response) =>{

                setData(response.data);

            })
            .catch((error) => {

                console.log("Error: " + error);
            });
    }

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        colors:['#bf360c', "#4a148c"],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Cannot Identify Letters', 'Can Identify Letters', 'Can Pronounce Words', 'Can Read Paragraph', 'Can Read and Comprehend Story'],
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "%"
                }
            }
        }
    }

    useEffect(() => {

        fetchData();

    }, []);


    return(


        <div id="bm-chart-ll-overall">
            <Chart options={options} series={data} type="bar" height={350} width="100%"/>
        </div>

    );
}

export default BaselineMidtermLiteracy;