import Dashboard from "../pages/Dashboard";


const ServiceProvidersAggregated = () => {

    return(
        <Dashboard>
            <h1 style={{marginTop:"20%"}} className="text-center">This page is been update <br/>or <br/>under construction</h1>
        </Dashboard>
    )
}

export default ServiceProvidersAggregated;