import Dashboard from '../pages/Dashboard';
import DataCount from "./DataCount";
import GenderCount from "./GenderCount";
import LiteracyOverall from "./baseline/LiteracyOverall";
import EndlineLiteracyOverall from './endline/EndlineLiteracyOverall';
import NumeracyOverall from "./baseline/NumeracyOverall";
import EndlineNumeracyOverall from "./endline/EndlineNumeracyOverall";
import MathOperations from "./baseline/MathOperations";
import EndlineMathOperations from "./endline/EndlineMathOperations";
import StudentPerLevel from "./baseline/StudentPerLevel";
import StudentPerDistrict from "./baseline/StudentPerDistrict";
import EndlineStudentPerDistrict from "./endline/EndlineStudentPerDistrict";
import EndlineStudentPerLevel from "./endline/EndlineStudentPerLevel";
import LiteracyLevelGrade from "./baseline/LiteracyLevelGrade";
import LiteracyLevelGradeDistrict from "./baseline/LiteracyLevelGradeDistrict";
import EndlineLiteracyLevelGrade from "./endline/EndlineLiteracyLevelGrade";
import EndlineLiteracyLevelGradeDistrict from "./endline/EndlineLiteracyLevelGradeDistrict";
import DigitLevelGrade from "./baseline/DigitLevelGrade";
import DigitLevelGradeDistrict from "./baseline/DigitLevelGradeDistrict";
import SchoolsDirectory from "./SchoolsDirectory";
import ServiceProviders from "./ServiceProviders";
import AdvancedAnalysis from "./AdvancedAnalysis";
import IndicatorMap from "../components/IndicatorMap";
import level from '../images/Level.jpg';
import literacy from '../images/Literacy.jpg';
import math from '../images/Maths.jpg';
import math_operations from '../images/maths_operations.jpg';
import AggregatedNumeracy from "./AggregatedNumeracy";
import AggregatedMathOperations from "./AggregatedMathOperations";
import BaselineMidtermLiteracy from "./BaselineMidtermLiteracy";
import BaselineMidtermNumeracy from "./BaselineMidtermNumeracy";
import BaselineMidtermMath from "./BaselineMidtermMath";
import AggregatedLiteracy from "./AggregatedLiteracy";
import AggregatedNumeracyBaseline from "./AggregatedNumeracyBaseline";
import AggregatedMathOperationsBaseline from "./AggregatedMathOperationsBaseline";
import AggregatedLiteracyBaseline from "./AggregatedLiteracyBaseline";
import AggregatedLiteracyEndline from "./AggregatedLiteracyEndline";
import AggregatedMathOperationsEndline from "./AggregatedMathOperationsEndline";
import AggregatedNumeracyEndline from "./AggregatedNumeracyEndline";

const DashboardMain = () => {

    return(

        <Dashboard>
            <ul data-role="tabs" data-expand="true">
                <li><a href="#_target_1">Overview</a></li>
                <li><a href="#_target_2">Baseline Assessment</a></li>
                <li><a href="#_target_3">Midterm Assessment</a></li>
                <li><a href="#_target_4">Schools Directory</a></li>
                <li><a href="#target_5">Service Providers</a></li>
                <li><a href="#target_6">ACER Metrics</a></li>
                <li><a href="#target_7">Schools Location</a></li>
                <li><a href="#target_8">Advanced Analysis</a></li>
            </ul>
            <div className="border bd-default no-border-top p-2">
                <div id="_target_1">
                    <div className="container-fluid">
                        <div className="grid">
                            <DataCount/>
                            <GenderCount/>
                            <div className="row">
                               <div className="card">
                                   <div className="card-content m-4">
                                       <h5 className="text-center">Baseline and Midterm Assessment Comparison</h5>
                                       <div className="row">
                                           <div className="cell-md-6">
                                               <h6 className="text-center">Percentage of Student that can Identify Digits</h6>
                                               <BaselineMidtermNumeracy/>
                                           </div>
                                           <div className="cell-md-6">
                                               <h6 className="text-center">Percentage of Students that can do Math Operations</h6>
                                               <BaselineMidtermMath/>
                                           </div>
                                       </div>
                                       <div className="row">
                                           <div className="cell-md-12 mt-4">
                                               <h6 className="text-center">Percentage of Literacy Level Attained</h6>
                                               <BaselineMidtermLiteracy/>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div className="card-content m-4">
                                        <h5 className="text-center">Treatment and Control Schools Comparison</h5>
                                        <div className="row">
                                            <div className="cell-md-6">
                                                <h6 className="text-center">Percentage of Students that can Identify Digits</h6>
                                                <AggregatedNumeracy/>
                                            </div>
                                            <div className="cell-md-6">
                                                <h6 className="text-center">Percentage of Students that can do Math Operations</h6>
                                                <AggregatedMathOperations/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cell-md-12 mt-4">
                                                <h6 className="text-center">Percentage of Literacy Level Attained</h6>
                                                <AggregatedLiteracy/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div className="card-content m-4">
                                        <h5 className="text-center">Baseline Assessment (Treatment Schools & Control Schools Comparison)</h5>
                                        <div className="row">
                                            <div className="cell-md-6">
                                                <h6 className="text-center">Percentage of Students that can Identify Digits</h6>
                                                <AggregatedNumeracyBaseline/>
                                            </div>
                                            <div className="cell-md-6">
                                                <h6 className="text-center">Percentage of Students that can do Math Operations</h6>
                                                <AggregatedMathOperationsBaseline/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cell-md-12 mt-4">
                                                <h6 className="text-center">Percentage of Literacy Level Attained</h6>
                                                <AggregatedLiteracyBaseline/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div className="card-content m-4">
                                        <h5 className="text-center">Midterm Assessment (Treatment Schools & Control Schools Comparison)</h5>
                                        <div className="row">
                                            <div className="cell-md-6">
                                                <h6 className="text-center">Percentage of Students that can Identify Digits</h6>
                                                <AggregatedNumeracyEndline/>
                                            </div>
                                            <div className="cell-md-6">
                                                <h6 className="text-center">Percentage of Students that can do Math Operations</h6>
                                                <AggregatedMathOperationsEndline/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cell-md-12 mt-4">
                                                <h6 className="text-center">Percentage of Literacy Level Attained</h6>
                                                <AggregatedLiteracyEndline/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_target_2">
                    <div className="container-fluid">
                        <div className="grid">
                            <div className="row">
                                <div className="cell-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className="text-center text-leader">Students Assessed Per Level</p>
                                        </div>
                                        <div className="card-content">
                                            <StudentPerLevel/>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className="text-center text-leader">Students Assessed Per Level
                                                (District)</p>
                                        </div>
                                        <div className="card-content">
                                            <StudentPerDistrict/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className="text-center text-leader">Literarcy Level Per Grade (%)</p>
                                        </div>
                                        <div className="card-header">
                                            <LiteracyLevelGrade/>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className="text-center text-leader">Literarcy Level Per Grade By
                                                District (%)</p>
                                        </div>
                                        <div className="card-content">
                                            <LiteracyLevelGradeDistrict/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className="text-leader text-center">% of students per Class
                                                identifying numbers.</p>
                                        </div>
                                        <div className="card-content">
                                            <DigitLevelGrade/>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className="text-leader text-center">% of students per Class
                                                identifying numbers by district.</p>
                                        </div>
                                        <div className="card-content">
                                            <DigitLevelGradeDistrict/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_target_3">
                    <div className="container-fluid">
                        <div className="grid">
                            <div className="row">
                                <div className="cell-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className="text-center text-leader">Students Assessed Per Level</p>
                                        </div>
                                        <div className="card-content">
                                            <EndlineStudentPerLevel/>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className="text-center text-leader">Students Assessed Per Level
                                                (District)</p>
                                        </div>
                                        <div className="card-content">
                                            <EndlineStudentPerDistrict/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className="text-center text-leader">Literacy Level Per Grade (%)</p>
                                        </div>
                                        <div className="card-header">
                                            <EndlineLiteracyLevelGrade/>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className="text-center text-leader">Literacy Level Per Grade By
                                                District (%)</p>
                                        </div>
                                        <div className="card-content">
                                            <EndlineLiteracyLevelGradeDistrict/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="_target_4">
                    <div className="container-fluid">
                        <div className="grid">
                            <div className="row">
                                <div className="cell-md-12">
                                    <SchoolsDirectory/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="target_5">
                    <ServiceProviders/>
                </div>
                <div id="target_6">
                    <div className="container-fluid bg-white">
                        <div className="grid">
                            <div className="row">
                                <div className="cell-md-12">
                                    <p className='text-leader2'>
                                        <strong>Class were ASER abilities should be learned according to Sierra Leone’s Basic Education Curriculum</strong>
                                    </p>
                                    <p>
                                        <img src={level} alt="level.jpg"/>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-12">
                                    <p className="text-leader2">
                                        <strong>Literacy</strong>
                                        <ul>
                                            <li>Beginner: cannot identify letters of the alphabet</li>
                                            <li>Letter Recognition: can identify letters of the alphabet both lowercase and uppercase</li>
                                            <li>Word: can read words</li>
                                            <li>Paragraph: can read simple sentences at standard 1 level</li>
                                            <li>Story: can read and comprehend a story at standard 2 level</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-12">
                                    <p>
                                        <img src={literacy} alt="literacy.jpg"/>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-12">
                                    <p className="text-leader2">
                                        <strong>Numeracy: Number Identification</strong>
                                        <ul>
                                            <li>Beginner: cannot identify single digit numbers</li>
                                            <li>One-digit: can identify single digit numbers</li>
                                            <li>Two-digit: can identify two-digit numbers</li>
                                            <li>Three-digit recognition: can identify three-digit numbers</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-12">
                                    <p>
                                        <img src={math} alt="math.jpg"/>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-12">
                                    <p className="text-leader2">
                                        <strong>Numeracy: Math Operations</strong>
                                        <ul>
                                            <li>Addition: can correctly perform additions of two-digit numbers</li>
                                            <li>Subtraction: can correctly perform subtractions of two-digit numbers</li>
                                            <li>Multiplication: can correctly perform multiplications (two digits time one digit number)</li>
                                            <li>Division: can correctly perform divisions (two digits time divided in one digit number)</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="cell-md-12">
                                    <p>
                                        <img src={math_operations} alt="math_operations.jpg"/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="target_7">
                    <div className="container-fluid">
                        <div className="grid">
                            <IndicatorMap/>
                        </div>
                    </div>
                </div>
                <div id="target_8">
                    <AdvancedAnalysis/>
                </div>
            </div>
        </Dashboard>

    );
}

export default DashboardMain;

