import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import SingleSchoolDirectory from './components/SingleSchoolDirectory';
import DashboardMain from "./components/DashboardMain";
import Savethechildren from "./components/Savethechildren";
import RisingAcademy from "./components/RisingAcademy";
import WorldVision from "./components/WorldVision";
import Educaid from "./components/Educaid";
import Nyafsl from "./components/Nyafsl";
import Compare from "./components/baseline/Compare";
import ServiceProvidersAggregated from "./components/ServiceProvidersAggregated";
import TreatmentSchoolAggregated from "./components/TreatmentSchoolAggregated";
import ControlSchoolsAggregated from "./components/ControlSchoolsAggregated";
import MonitoringAndEvaluation from "./components/MonitoringAndEvaluation";
import CompareSchools from "./components/CompareSchools";
import CompareServiceProviders from "./components/CompareServiceProviders";
import SchoolsComparison from "./components/SchoolsComparison";
const App = () => {

  return(

      <Router>
        <Switch>
          <Route path="/" exact component={DashboardMain}/>
          <Route path="/school/:name/:emis" exact component={SingleSchoolDirectory}/>
          <Route path="/save-the-children" exact component={Savethechildren}/>
          <Route path="/rising-academy-network" exact component={RisingAcademy}/>
          <Route path="/world-vision-international" exact component={WorldVision}/>
          <Route path="/educaid" exact component={Educaid}/>
          <Route path="/nyafsl" eaxct component={Nyafsl}/>
          <Route path="/baseline-and-midterm/compare-district" exact component={Compare}/>
          <Route path="/service-providers-aggregated" exact component={ServiceProvidersAggregated}/>
          <Route path="/treatment-schools-aggregated" exact component={TreatmentSchoolAggregated}/>
          <Route path="/control-schools-aggregated" exact component={ControlSchoolsAggregated}/>
          <Route path="/monitoring-and-evaluation" exact component={MonitoringAndEvaluation}/>
          <Route path="/compare-schools" exact component={CompareSchools}/>
          <Route path="/compare-service-providers" exact component={CompareServiceProviders}/>
          <Route path="/schools-comparison" exact component={SchoolsComparison}/>
        </Switch>
      </Router>
  )
}

export default App;
